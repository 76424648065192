import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import loadableBase from '../../../utils/loadable';

import { fetchFilingsSources } from '../../../actions/filings.actions';

import SectionWrapper from '../SectionWrapper';
import Preloader from '../../Preloader';

import Styles from '../styles.module.scss';

const loadable = (fn) => (
  loadableBase(fn, {
    fallback: (
      <div style={{ height: 'calc(100% - 1.5em)', display: 'flex', justifyContent: 'center' }}>
        <Preloader loading transform />
      </div>
    ),
  })
);

const Filings = loadable(() => import('../Filings'));

const FilingsWrapper = (props) => {
  const { handleSectionTitle } = props;
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    const asyncCallFunction = async () => {
      await dispatch(fetchFilingsSources({ active: true }));
      setIsLoading(false);
    };
    asyncCallFunction();
  }, []);

  if (isLoading) return null;

  return (
    <>
      <SectionWrapper
        type="filings_by_sources"
        handleSectionTitle={handleSectionTitle}
        videoUrl="https://cityfalcon.wistia.com/medias/zj9uvpl28w"
        className={Styles['company-data']}
        minHeight={300}
      >
        <Filings byType="source" />
      </SectionWrapper>

      <SectionWrapper
        type="filings_by_categories"
        handleSectionTitle={handleSectionTitle}
        videoUrl="https://cityfalcon.wistia.com/medias/zj9uvpl28w"
        className={Styles['company-data']}
        minHeight={300}
      >
        <Filings byType="category" />
      </SectionWrapper>
    </>
  );
};

export default FilingsWrapper;
